import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import { CircleMarker, MapContainer, Popup, TileLayer, useMapEvents } from 'react-leaflet';

import { API_URL, WS_URL } from '../shared/config';
import { cacheBustParam } from '../shared/utils';
import { SensorListItem } from './SensorListItem';

import 'leaflet/dist/leaflet.css';
import { calculateRadius } from '../shared/map_utils';
import { useWebSocket } from 'react-use-websocket/dist/lib/use-websocket';
import { Header } from './shared/Header';

const fetchSensors = async (setSensors: Function, sendJsonMessage: Function) => {
  const response = await fetch(`${API_URL}/map-sensors?${cacheBustParam()}`);
  const sensors = await response.json();

  setSensors([...sensors]);

  sendJsonMessage({ "sensors": sensors.map(item => item.id.toString()) });

  fetchReadings(sensors, setSensors);
};

const fetchReadings = async (sensors, setSensors) => {
  const sensorList = sensors.map((item) => item.id.toString());
  const response = await fetch(`${API_URL}/all-latest-readings?sensors=&${cacheBustParam()}`);
  const sensorReadings = await response.json();

  const combinedSensors = sensors.map((sensor) => {
    const sensorReading = sensorReadings.filter((reading) => sensor.id === reading.id)[0];
    return { ...sensor, ...sensorReading };
  });

  setSensors([...combinedSensors]);
};

const Map = (props) => {
  const [sensors, setSensors] = useState([]);

  const {
    sendJsonMessage,
  } = useWebSocket(WS_URL, {
    onOpen: () => sendJsonMessage({ "sensors": [] }),
    //Will attempt to reconnect on all close events, such as server shutting down
    onMessage: (message) => {
      const data = JSON.parse(message.data);
      const new_results = sensors.map((item) => data['id'] == item['id'] && data['timestamp'] > item['timestamp'] ? { ...item, ...data } : item);
      setSensors([...new_results]);
    },
    shouldReconnect: (closeEvent) => true,
  });

  useEffect(() => {
    fetchSensors(setSensors, sendJsonMessage);
  }, []);
  return <>
    <Helmet>
      <title>weather station map</title>
      <link rel="canonical" href="https://dankweather.com/map" />
    </Helmet>
    <Header />
    <MapContainer className="map-container" center={[36.739461528447116, -96.77514508177013]} zoom={4} scrollWheelZoom={true}>
      <MapMarkers sensors={sensors} />
    </MapContainer>
  </>;
}

const MapMarkers = (props) => {
  const [circleRadius, setCircleRadius] = useState(1);
  const mapEvents = useMapEvents({
    zoomend: () => {
      setCircleRadius(calculateRadius(mapEvents.getZoom()));
    },
  });
  return <>
    <TileLayer
      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />
    {props.sensors.map(sensor => <MapMarker sensor={sensor} circleRadius={circleRadius} key={sensor.id} />)}
  </>;
}

const MapMarker = (props) => {
  const [lat, lon] = props.sensor.latlon;
  if (typeof lat === 'number' && typeof lon === 'number') {
    return <CircleMarker center={[lat, lon]} key={props.sensor.id} radius={props.circleRadius}>
      <Popup className="sensor-popup">
        <SensorListItem {...props.sensor} />
      </Popup>
    </CircleMarker>
  }
  return null;
}

export { Map };