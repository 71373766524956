import React from "react";


const Footer = (props) => {
    return <></>;
    return <footer>
        <a href="https://dankweather.com">dank weather</a> is a
        personal weather station and temperature sensor aggregator
        that strives for simplicity and speed. Allowing per second
        logging with millisecond streaming data.
    </footer>;
};

export { Footer };

