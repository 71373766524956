import React from 'react';
import { Helmet } from "react-helmet";

import { Header } from './shared/Header';

export const Privacy = (props) => {
    return (
        <div>
            <Helmet>
                <title>dank weather privacy policy</title>
                <link rel="canonical" href="https://dankweather.com/privacy" />
            </Helmet>
            <Header />
            <div className="privacy-container">
                <h2>Privacy Policy</h2>
                <p>
                    TL;DR: We do not sell your personal information, but weather and sensor data may be used or sold commercially.
                </p>
                <div>
                    <ul>
                        <li>Weather and sensor data may be used for commercial or non-commercial use</li>
                        <li>Personal information will not be sold.</li>
                        <li>As long as feasible, you will be able to download your data.</li>
                        <li>If you have an account, you can request to delete it.</li>
                        <li>We try our best to secure your information, but we cannot guarantee that <em>nothing</em> will ever happen to it.</li>
                        <li>We make no guarantees of uptime or availability of this service.</li>
                        <li>We collect your push notification device id and live activity push ids. These are required to send live updates of weather data to your device.</li>
                        <li>Other data related to your device might be sent and collected as logs for debugging and may be used in aggregate to determine things like usage.</li>
                        <li>Web and service logs are created and may contain certain personal information, like browser, IP address, geo-location headers.</li>
                        <li>We reserve the right to limit API or website access for any reason.</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}