import { SENSORS } from "./config";

const _sensorCache = new Set<number>();
let _sensorInit: boolean = false;

const saveSensors = (sensors) => {
    localStorage.setItem('sensors', JSON.stringify(Array.from(sensors)));
};

const _loadSavedSensors = () => {
    if (!_sensorInit) {
        let sensorList : number[] = [];
        if (localStorage.getItem('sensors') === null) {
            sensorList = SENSORS;
            saveSensors(sensorList);
        } else {
            sensorList = JSON.parse(localStorage.getItem('sensors') ?? '[]');
        }
        sensorList.forEach((sensor) => _sensorCache.add(sensor));
        _sensorInit = true;
    }

};

const getSavedSensors = () : number[] => {
    _loadSavedSensors();
    return Array.from(_sensorCache);
}

const addSensor = (sensor: number) => {
    _loadSavedSensors();
    if (_sensorCache.has(sensor)) {
        return;
    }
    _sensorCache.add(sensor);
    saveSensors(_sensorCache);
};

const removeSensor = (sensor: number) => {
    _loadSavedSensors();
    if (_sensorCache.has(sensor)) {
        _sensorCache.delete(sensor);
        saveSensors(_sensorCache);
    }
};

const hasSensor = (sensor: number) => {
    _loadSavedSensors();
    return _sensorCache.has(sensor);
};

export {
    addSensor,
    saveSensors,
    getSavedSensors,
    removeSensor,
    hasSensor,
};