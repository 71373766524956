import React, { useEffect, useState } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { Helmet } from "react-helmet";

import { ReadingContainer } from './ReadingContainer';
import { API_URL, SENSORS, WS_URL } from '../shared/config';
import { cacheBustParam } from '../shared/utils';
import { Header } from './shared/Header';
import { getSavedSensors } from '../shared/saved-sensors';
import { Footer } from './shared/Footer';


async function loadReadings(callback, sensorList) {
  const response = await fetch(`${API_URL}/latest-readings?sensors=${sensorList}&${cacheBustParam()}`);
  const readings = await response.json();
  callback([...readings]);
}

const WeatherContainer = (props) => {
  const [readings, setReadings] = useState([]);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const sensorList = urlParams.get('sensors') ? urlParams.get('sensors')?.split(',') : getSavedSensors().map((item) => item.toString());

  const {
    sendMessage,
    sendJsonMessage,
    lastMessage,
    lastJsonMessage,
    readyState,
    getWebSocket,
  } = useWebSocket(WS_URL, {
    onOpen: () => sendJsonMessage({ "sensors": sensorList }),
    //Will attempt to reconnect on all close events, such as server shutting down
    onMessage: (message) => {
      const data = JSON.parse(message.data);
      const new_results = readings.map((item) => {
        if (data['id'] === item['id'] && data['timestamp'] > item['timestamp']) {
          return Object.assign(item, data);
        }
        return item;
      });
      setReadings([...new_results]);
    },
    shouldReconnect: (closeEvent) => true,
  });

  useEffect(() => {
    loadReadings(setReadings, sensorList);
    const onWindowFocus = () => {
      loadReadings(setReadings, sensorList);
    };

    window.addEventListener("focus", onWindowFocus);
    return () => {
      window.removeEventListener("focus", onWindowFocus);
    };
  }, []);

  return <div className="page-container">
    <Helmet>
      <meta charSet="utf-8" />
      <title>dank weather</title>
      <link rel="canonical" href="https://dankweather.com" />
    </Helmet>
    <Header />
    <div className="weather-container">
      {readings.map((reading) =>
        <ReadingContainer
          key={`sensor-${reading['id']}`}
          temp={reading['temperature']}
          tempType={reading['temp_type']}
          humidity={reading['humidity']}
          name={reading['name']}
          timestamp={reading['timestamp']}
          dailyRain={reading['dailyrain']}
          eventRain={reading['eventrain']}
          windSpeed={reading['windspeed']}
          windDir={reading['winddir']}
          windGust={reading['windgust']}
          windType={reading['windspeed_type']}
          indoorTemp={reading['indoor_temp']}
          indoorHumidity={reading['indoor_humidity']}
          dankName={reading['dank_name']}
          feelsLike={reading['feels_like']}
        />
      )}
    </div>
    <Footer />
  </div>;
}

export { WeatherContainer };