import React from 'react';

import { degreeToDirection, formatHumidity, formatNumber, formatTemperature, tempConvert, timeSinceFormatter } from '../../shared/utils';

const RainContainer = (props) => {
  const rainAmount = props.eventRain ?? props.dailyRain;
  const rainType = (props.eventRain !== null) ? "event" : "today";
  return (rainAmount !== null) ? <div className="dailyrain">{formatNumber(rainAmount, 2)}" {rainType}</div> : <></>;
};

const WindContainer = (props) => {
  return (props.windSpeed !== null && typeof props.windSpeed !== 'undefined') ?
    <div className="windspeed">{formatNumber(props.windSpeed)}mph{((props.windGust && props.windGust > 0) ? ` (${formatNumber(props.windGust)}mph gust)` : '')}  {!props.hideDirection && <WindDirectionContainer windSpeed={props.windSpeed} windDir={props.windDir} />}</div> : <></>;
};

const WindDirectionContainer = (props) => {
  return <>{(props.windSpeed !== null && props.windSpeed > 0 && props.windDir !== null && ` ${degreeToDirection(props.windDir)}`)}</>;
}

const IndoorTempContainer = (props) => {
  if (props.indoorTemp !== null && props.indoorTemp !== 0) {
    return <div className="indoor-temp">Indoor: {formatTemperature(props.indoorTemp, props.tempType)}</div>;
  } else {
    return <></>;
  }
}

const TimeSinceContainer = (props) => {
  return <div className="lastupdate">{timeSinceFormatter(props.timeSince)}</div>;
}

const TempContainer = (props) => {
  return <div className="temp">{formatTemperature(props.temperature, props.tempType)}</div>;
}

const HumidityContainer = (props) => {
  return <div className="humidity">{formatHumidity(props.humidity)}</div>;
}

const FeelsLikeContainer = (props) => {
  if (props.feelsLike) {
    return <div className="feelslike">Feels like: {formatTemperature(props.feelsLike, props.tempType)}</div>
  } else if (props.alwaysShow) {
    return <div className="feelslike">Feels like: {formatTemperature(props.temperature, props.tempType)}</div>
  } else {
    return <></>;
  }
}

export {
  RainContainer,
  WindContainer,
  WindDirectionContainer,
  IndoorTempContainer,
  TimeSinceContainer,
  TempContainer,
  HumidityContainer,
  FeelsLikeContainer,
};