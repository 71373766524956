
// Converts given temperature to fahrenheit.
const tempConvert = (temperature: number, tempType: string, forceFahrenheit: boolean = false): number => {
  if (tempType === 'CELSIUS') {
    return ((temperature * 9 / 5.0) + 32);
  } else {
    return temperature;
  }
};

const formatNumber = (num: number, decimal = 1): string => {

  return (typeof num === 'number') ? num.toFixed(decimal) : '';
};

const formatTemperature = (temperature: number, tempType: string, includeType: boolean = true, forceFahrenheit: boolean = false,): string => {
  const tempSuffix = includeType ? '°F' : ''
  return (typeof temperature === 'number') ?
    `${formatNumber(tempConvert(temperature, tempType))}${tempSuffix}` : '';
};

const formatHumidity = (humidity: number) => {
  return (typeof humidity === 'number') ? `${formatNumber(humidity)}%` : '';
}

const findMaxFeelsLike = (readings) => {
  if (typeof readings === 'undefined' || readings.length === 0) {
    return '';
  }
  return readings.reduce((previous, reading) => {
    const current = reading.feels_like;
    return (previous > current) ? previous : current;
  });
};

const calculateDewPoint = (temperature: number, humidity: number): number => {
  return temperature - ((100 - humidity) / 5.0);
}


// Cardinal Direction	Degree Direction
// N 348.75 - 11.25
// NNE 11.25 - 33.75
// NE 33.75 - 56.25
// ENE 56.25 - 78.75
// E 78.75 - 101.25
// ESE 101.25 - 123.75
// SE 123.75 - 146.25
// SSE 146.25 - 168.75
// S 168.75 - 191.25
// SSW 191.25 - 213.75
// SW 213.75 - 236.25
// WSW 236.25 - 258.75
// W 258.75 - 281.25
// WNW 281.25 - 303.75
// NW 303.75 - 326.25
// NNW 326.25 - 348.75
const degreeToDirection = (degree: number): string => {
  // This should probably be a list of directions and adding 22.5.
  // but meh.
  if (degree > 360 || degree < 0) {
    return '';
  } else if (degree >= 348.75 || degree < 11.25) {
    return 'N';
  } else if (degree >= 11.25 && degree < 33.75) {
    return 'NNE';
  } else if (degree >= 33.75 && degree < 56.25) {
    return 'NE'
  } else if (degree >= 56.25 && degree < 78.75) {
    return 'ENE'
  } else if (degree >= 78.75 && degree < 101.25) {
    return 'E'
  } else if (degree >= 101.25 && degree < 123.75) {
    return 'ESE'
  } else if (degree >= 123.75 && degree < 146.25) {
    return 'SE'
  } else if (degree >= 146.25 && degree < 168.75) {
    return 'SSE'
  } else if (degree >= 168.75 && degree < 191.25) {
    return 'S'
  } else if (degree >= 191.25 && degree < 213.75) {
    return 'SSW'
  } else if (degree >= 213.75 && degree < 236.25) {
    return 'SW'
  } else if (degree >= 236.25 && degree < 258.75) {
    return 'WSW'
  } else if (degree >= 258.75 && degree < 281.25) {
    return 'W'
  } else if (degree >= 281.25 && degree < 303.75) {
    return 'WNW'
  } else if (degree >= 303.75 && degree < 326.25) {
    return 'NW'
  } else if (degree >= 326.25 && degree < 348.75) {
    return 'NNW'
  } else {
    return '';
  }
}

const cacheBustParam = (): string => {
  return `${Math.round(Date.now() / 1000)}`;
}

const arrowRotation = (degrees: number): number => {
  return degrees + 90;
}

const calculateTimeSince = (timestamp: number): number => {
  return Math.round(Date.now() / 1000 - timestamp);
}

const timeSinceFormatter = (timeSince: number): string => {
  if (timeSince < 180) {
    return `${timeSince} seconds ago`;
  }
  timeSince = timeSince / 60.0;
  if (timeSince < 60) {
    const [formattedTime, letter] = roundAndLetter(timeSince);
    return `${formattedTime} minute${letter} ago`;
  }
  timeSince = timeSince / 60.0;
  if (timeSince < 24) {
    const [formattedTime, letter] = roundAndLetter(timeSince);
    return `${formattedTime} hour${letter} ago`;
  }
  timeSince = timeSince / 24.0;
  const [formattedTime, letter] = roundAndLetter(timeSince);
  return `${formattedTime} day${letter} ago`;
}

const pluralLetter = (amount: number): string => {
  return (amount === 1) ? '' : 's';
}

const roundAndLetter = (amount: number): Array<string> => {
  amount = Math.round(amount);
  return [amount.toString(), pluralLetter(amount)];
}

export {
  tempConvert,
  formatHumidity,
  formatNumber,
  formatTemperature,
  degreeToDirection,
  cacheBustParam,
  arrowRotation,
  findMaxFeelsLike,
  calculateDewPoint,
  calculateTimeSince,
  timeSinceFormatter,
  pluralLetter,
  roundAndLetter,
};