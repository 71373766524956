import React from 'react';
import { Link } from 'react-router-dom';

export const Header = (props) => {
    return <div className="header">
    <div className='header-title'>
      <span><Link to="/">home</Link></span>
    </div>
    <div className='header-link'>
      <Link to="/sensors">sensor list</Link> | <Link to="/map">map</Link>
    </div>
    <div className='header-extra'>
      <span><Link to="/faq">faq</Link></span>
    </div>
  </div>;
}