import React from 'react';
import { Helmet } from "react-helmet";

import { Header } from './shared/Header';

import ambientscreenshot from '../static/ambient-screenshot.jpg';

export const FaqItem = (props) => {
    return <div className="faq-item-container">
        <div className="faq-item-title" id={props.anchor}><h3>{props.title}</h3></div>
        <div className="faq-item-content">{props.content}</div>
    </div>;

}

export const Faq = (props) => {
    return (
        <div>
            <Helmet>
                <title>dank weather faq</title>
                <link rel="canonical" href="https://dankweather.com/faq" />
            </Helmet>
            <Header />
            <div className="faq-container">
                <h2>Frequently Asked Questions</h2>
                <FaqItem
                    title="How do I setup an Ambient Weather PWS for dank weather?"
                    anchor="ambient-setup"
                    content={<>
                        <p>
                            Data can be fed using the custom server option under the awnet config.
                            <ul>
                                <li><strong>Protocol Type Same As</strong>: <code>Ambient Weather</code></li>
                                <li><strong>Server IP / Hostname</strong>: <code>api.dankweather.com</code></li>
                                <li><strong>Path</strong>: <code>/ambient-weather?</code></li>
                                <li><strong>Port</strong>: <code>80</code></li>
                                <li><strong>Upload Interval</strong>: <code>16</code></li>
                            </ul>
                            Few notes:
                            <ul>
                                <li>The Path needs to contain the <code>?</code> at the end.</li>
                                <li>The PWS does not support SSL.</li>
                                <li>The shortest frequency allowed by the PWS is 16 seconds. dank weather supports and recommends the shortest available interval, but the interval is ultimately at your discretion. </li>
                            </ul>
                            Once you begin feeding, email <a href="mailto:admin@dankweather.com">admin@dankweather.com</a> and I can create you an account to associate it with.
                        </p>
                        <p className="ambient-screenshot">
                            <img src={ambientscreenshot} height={300} />
                        </p>
                    </>}
                />
                <FaqItem
                    title="How do I setup my temperature sensor with dank weather?"
                    content={<>
                        <p>
                            If you have any temperature or humidity sensor data, you can log it to the <code>/log</code> endpoint on <code>api.dankweather.com</code>.
                        </p>
                        <p>
                            View the <a href="https://api.dankweather.com/docs#/default/log_data_log_post">FastAPI generated docs</a> for more information.
                        </p>
                    </>}
                />
            </div>
        </div>
    );
}