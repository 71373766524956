import React from 'react';
import { createRoot } from 'react-dom/client';
import {
  BrowserRouter,
  Route,
  Routes,
} from "react-router-dom";
import { WeatherContainer } from "./components/WeatherContainer";
import { SensorListContainer } from './components/SensorListContainer';
import { SensorDetailContainer } from './components/SensorDetailsContainer';
import { SolarDetailsContainer } from './components/SolarDetailsContainer';
import { Privacy } from './components/Privacy';
import { Faq } from './components/Faq';
import { Map } from './components/Map';


createRoot(document.getElementById('app')!).render(<BrowserRouter>
  <Routes>
    <Route path="/" element={(<WeatherContainer />)} />
    <Route path="/sensors" element={(<SensorListContainer />)} />
    <Route path="/sensors/page/:pageNumberParam" element={<SensorListContainer />} />
    <Route path="/sensor/:sensorName" element={<SensorDetailContainer />} />
    <Route path="/solar/:sensorName" element={<SolarDetailsContainer />} />
    <Route path="/faq" element={<Faq />} />
    <Route path="/map" element={<Map />} />
    <Route path="/privacy" element={<Privacy />} />
  </Routes>
</BrowserRouter>)
