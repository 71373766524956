import React, { useEffect, useState } from 'react';

import { addSensor, hasSensor, removeSensor } from '../../shared/saved-sensors';

import StarSvg from '../../static/star.svg';
import StarFilledSvg from '../../static/star-filled.svg';



const Toggle = (props) => {
  const [checked, setChecked] = useState(hasSensor(props.id));

  return <div className="toggle-checkbox">
    <input
      type="checkbox"
      id={`${props.dank_name}-toggle`}
      checked={checked}
      onChange={(e) => {
        if (e.target.checked) {
          addSensor(props.id);
          setChecked(true);
        } else {
          removeSensor(props.id);
          setChecked(false);
        }
      }}
      title="Show sensor on homepage"
      aria-label='Show sensor on homepage'
    />
    <label
      title="Show sensor on homepage"
      aria-label='Show sensor on homepage'
      htmlFor={`${props.dank_name}-toggle`}
    ><img src={checked ? StarFilledSvg : StarSvg} className={checked ? 'selected' : ''} /></label>
  </div>;
};

export {
  Toggle,
};