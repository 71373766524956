import React, { useEffect, useState } from 'react';
import { calculateTimeSince, } from '../shared/utils';
import { Link } from 'react-router-dom';
import { FeelsLikeContainer, HumidityContainer, IndoorTempContainer, RainContainer, TempContainer, TimeSinceContainer, WindContainer } from './shared/ReadingContainers';

const ReadingContainer = (props) => {
  const [timeSince, setTimeSince] = useState(0);

  const timestamp = props.timestamp;

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeSince(calculateTimeSince(timestamp));
    }, 1000);
    return () => clearInterval(interval);
  }, [timestamp]);

  return <div className="sensor-reading-container">
    <h2><Link to={`/sensor/${props.dankName}`}>{props.name}</Link></h2>
    <TempContainer temperature={props.temp} tempType={props.tempType} />
    <FeelsLikeContainer temperature={props.temp} tempType={props.tempType} feelsLike={props.feelsLike} />
    <HumidityContainer humidity={props.humidity} />
    <RainContainer dailyRain={props.dailyRain} eventRain={props.eventRain} />
    <WindContainer windSpeed={props.windSpeed} windDir={props.windDir} windGust={props.windGust} windType={props.windType} />
    <IndoorTempContainer indoorTemp={props.indoorTemp} tempType={props.tempType} />
    <TimeSinceContainer timeSince={timeSince} />
  </div>;
}

export { ReadingContainer, WindContainer };