import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";


import { API_URL, WS_URL } from '../shared/config';
import { SensorListItem } from './SensorListItem';
import { useWebSocket } from 'react-use-websocket/dist/lib/use-websocket';
import { cacheBustParam } from '../shared/utils';
import { Header } from './shared/Header';
import { Link, useParams } from 'react-router-dom';

const PAGE_SIZE = 30;

const fetchSensors = async (setSensors: Function, sendJsonMessage: Function, pageNumber: number) => {
  const response = await fetch(`${API_URL}/list-sensors?&page_number=${pageNumber}&page_size=${PAGE_SIZE}&${cacheBustParam()}`);
  const sensors = await response.json();

  setSensors([...sensors]);

  sendJsonMessage({ "sensors": sensors.map(item => item.id.toString()) });

  fetchReadings(sensors, setSensors);
};

const fetchReadings = async (sensors, setSensors) => {
  const sensorList = sensors.map((item) => item.id.toString());
  const response = await fetch(`${API_URL}/latest-readings?sensors=${sensorList.join(',')}&${cacheBustParam()}`);
  const sensorReadings = await response.json();

  const combinedSensors = sensors.map((sensor) => {
    const sensorReading = sensorReadings.filter((reading) => sensor.id === reading.id)[0];
    return { ...sensor, ...sensorReading };
  });

  setSensors([...combinedSensors]);
};

const SensorListContainer = (props) => {

  const [sensors, setSensors] = useState([]);


  const { pageNumberParam } = useParams();
  const [pageNumber, setPageNumber] = useState(parseInt(pageNumberParam ?? '1'));

  const {
    sendMessage,
    sendJsonMessage,
    lastMessage,
    lastJsonMessage,
    readyState,
    getWebSocket,
  } = useWebSocket(WS_URL, {
    onOpen: () => sendJsonMessage({ "sensors": [] }),
    //Will attempt to reconnect on all close events, such as server shutting down
    onMessage: (message) => {
      const data = JSON.parse(message.data);
      const new_results = sensors.map((item) => data['id'] == item['id'] && data['timestamp'] > item['timestamp'] ? { ...item, ...data } : item);
      setSensors([...new_results]);
    },
    shouldReconnect: (closeEvent) => true,
  });

  useEffect(() => {
    fetchSensors(setSensors, sendJsonMessage, pageNumber);
  }, []);

  const updatePage = (pageNum: number) => {
    setPageNumber(pageNum);
    setSensors([]);
    fetchSensors(setSensors, sendJsonMessage, pageNum);
  }

  return <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>all sensors - dank weather</title>
      <link rel="canonical" href="https://dankweather.com/sensors" />
    </Helmet>
    <Header />
    <div className="sensor-list-container">
      {sensors.map((sensor) =>
        <SensorListItem key={`sensor-item-${sensor.id}`} {...sensor} />
      )}
    </div>
    <div className="sensor-list-pagination">
      <div>{(pageNumber > 1) && <Link to={`/sensors/page/${pageNumber - 1}`} onClick={() => updatePage(pageNumber - 1)}>previous page</Link>}</div>
      <div>{(sensors.length === PAGE_SIZE) && <Link to={`/sensors/page/${pageNumber + 1}`} onClick={() => updatePage(pageNumber + 1)}>next page</Link>}</div>
    </div>
  </>;
};

export { SensorListContainer };