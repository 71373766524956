import React from 'react';
import { Link } from 'react-router-dom';
import { calculateTimeSince } from '../shared/utils';
import { Toggle } from './shared/Toggle';
import { FeelsLikeContainer, HumidityContainer, RainContainer, TempContainer, TimeSinceContainer, WindContainer } from './shared/ReadingContainers';

const SensorListItem = (props) => {
  return <div className="sensor-list-item">
    <div className="sensor-information">
      <div className="sensor-title"><h2><Link to={`/sensor/${props.dank_name}`}>{props.name}</Link></h2></div>
      <div className="sensor-type">{props.kind} - {props.location}</div>
      <WindContainer windDir={props.winddir} windSpeed={props.windspeed} windGust={props.windgust} />
      <RainContainer dailyRain={props.dailyrain} eventRain={props.eventrain} />
      <TimeSinceContainer timeSince={calculateTimeSince(props.timestamp)} />
    </div>
    <div className="sensor-reading">
      <TempContainer temperature={props.temperature} tempType={props.temp_type} />
      <FeelsLikeContainer temperature={props.temperature} tempType={props.temp_type} feelsLike={props.feels_like} />
      <HumidityContainer humidity={props.humidity} />
      <div className="sensor-action">
        <Toggle id={props.id} dank_name={props.dank_name} />
      </div>
    </div>
  </div>
};


export { SensorListItem };