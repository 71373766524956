import React, { useCallback, useEffect, useState } from 'react';
import useWebSocket from 'react-use-websocket';
import { Helmet } from "react-helmet";
import { useSwipeable } from "react-swipeable";

import { Chart as ChartJS, CategoryScale, LinearScale, TimeScale, PointElement, LineElement, Tooltip, Legend } from 'chart.js';
import 'chartjs-adapter-date-fns';
ChartJS.register(CategoryScale, LinearScale, TimeScale, PointElement, LineElement, Tooltip, Legend);

import { API_URL, WS_URL, LLM_URL } from '../shared/config';
import { useParams } from 'react-router-dom';
import { cacheBustParam, timeSinceFormatter } from '../shared/utils';
import { Header } from './shared/Header';
import { getSavedSensors } from '../shared/saved-sensors';

import { Toggle } from './shared/Toggle';


const fetchSensor = async (sensorName: string, setSensor: Function, setReadings: Function, sendJsonMessage: Function, sensorId?: number) => {
  let urlString = '';
  if (typeof sensorId !== 'undefined') {
    urlString = `${API_URL}/sensor-details?sensor_id=${sensorId}&${cacheBustParam()}`;
  } else {
    urlString = `${API_URL}/sensor-details?name=${sensorName}&${cacheBustParam()}`;
  }
  const response = await fetch(urlString);
  const sensor = await response.json();
  setSensor({ ...sensor });

  if (typeof sensorId === 'undefined') {
    sensorId = sensor['id'];
  }

  sendJsonMessage({ "sensors": [`${sensorId}`,] });

  fetchReadings(sensorId, setReadings);
};

const fetchReadings = async (sensorId: number, setCurrentReading: Function) => {
  if (typeof sensorId === 'number') {
    const response = await fetch(`${API_URL}/latest-readings?sensors=${sensorId}&${cacheBustParam()}`);
    const currentReading = await response.json();

    setCurrentReading({ ...currentReading[0] });
  }

};

const fetchLlm = async (sensorName: string, setGenAiText: Function, setLlmPending: Function) => {
  setLlmPending(true);
  const response = await fetch(`${LLM_URL}/generate/${sensorName}&${cacheBustParam()}`);
  console.log(response);
  const message = await response.json();
  console.log(message);
  setGenAiText(message.message);
  setLlmPending(false);

}

const fetchHistorical = async (sensorId: number, duration: string, setReadings: Function) => { };


const SolarDetailsContainer = (props) => {
  const { sensorName } = useParams();

  if (sensorName === undefined) {
    return <div>Not found</div>;
  }

  const [sensor, setSensor] = useState({});
  const [currentReading, setCurrentReading] = useState({});

  const [tabView, setTabView] = useState('1');

  const [isGenAiEnabled, setGenAiEnabled] = useState(false);
  const [isGenAiPending, setGenAiPending] = useState(false);
  const [genAiText, setGenAiText] = useState("");

  const [sevenDayReadings, setSevenDayReadings] = useState([]);
  const [thirtyDayReadings, setThirtyDayReadings] = useState([]);
  const [sixHourReadings, setSixHourReadings] = useState([]);
  const [oneYearReadings, setOneYearReadings] = useState([]);
  const [oneHourReadings, setOneHourReadings] = useState([]);


  const getHistoricalData = () => {

    switch (tabView) {
      case '1':
        return sensor.history;
      case '7':
        return sevenDayReadings;
      case '30':
        return thirtyDayReadings;
      case '0.25':
        return sixHourReadings;
      case '365':
        return oneYearReadings;
      case '0.1':
        return oneHourReadings;
    }
  }

  const {
    sendMessage,
    sendJsonMessage,
    lastMessage,
    lastJsonMessage,
    readyState,
    getWebSocket,
  } = useWebSocket(WS_URL, {
    onOpen: () => sendJsonMessage({ "sensors": [] }),
    //Will attempt to reconnect on all close events, such as server shutting down
    onMessage: (message) => {
      const data = JSON.parse(message.data);
      // Only set if data is newer.
      if (data['id'] === sensor['id'] && data['timestamp'] > currentReading['timestamp']) {
        if (isGenAiEnabled) {
          enableAndFetchLlm();
        }
        setCurrentReading({ ...data });
      }
    },
    shouldReconnect: (closeEvent) => true,
  });

  useEffect(() => {
    fetchSensor(sensorName, setSensor, setCurrentReading, sendJsonMessage);
  }, []);

  const [timeSince, setTimeSince] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {

      setTimeSince(Math.round(Date.now() / 1000 - currentReading.timestamp));
    }, 1000);
    return () => clearInterval(interval);
  }, [currentReading]);

  useEffect(() => {
    // This is removed since when switching with swipe/keyboard, the endpoint is hit twice.
    // I believe this was added when sitting on an inactive tab, but not 100% sure.
    // The onWindowFocus definitely is. This might just not be needed at all.
    // fetchReadings(sensor.id, setCurrentReading);
    const onWindowFocus = () => {
      fetchReadings(sensor.id, setCurrentReading);
    };

    window.addEventListener("focus", onWindowFocus);
    return () => {
      window.removeEventListener("focus", onWindowFocus);
    };
    // setInterval(() => loadReadings(setReadings, sensorList), 15 * 1000);
  }, [sensor,]);

  const handleKeyPress = useCallback((event) => {
    if (event.key === 'ArrowLeft') {
      handleSwipe('right');
    } else if (event.key === 'ArrowRight') {
      handleSwipe('left');
    }
  }, [sensor]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    return () => document.removeEventListener('keydown', handleKeyPress);
  }, [handleKeyPress, sensor]);

  const handleSwipe = (direction: string) => {
    let sensorIndex = getSavedSensors().indexOf(sensor['id']);

    if (direction === 'left') {
      sensorIndex += 1;
    } else {
      sensorIndex -= 1;
    }

    if (sensorIndex >= 0 && sensorIndex < getSavedSensors().length) {
      setSensor({});
      setCurrentReading({});

      setTabView('1');

      setSevenDayReadings([]);
      setThirtyDayReadings([]);
      setSixHourReadings([]);
      setOneYearReadings([]);
      setOneHourReadings([]);

      fetchSensor('', setSensor, setCurrentReading, sendJsonMessage, getSavedSensors()[sensorIndex]);
    }

  }

  const swipeHandlers = useSwipeable({
    onSwipedRight: (eventData) => {
      handleSwipe('right');
    },
    onSwipedLeft: (eventData) => {
      handleSwipe('left');
    },

    delta: 10,                             // min distance(px) before a swipe starts. *See Notes*
    preventScrollOnSwipe: false,           // prevents scroll during swipe (*See Details*)
    trackTouch: true,                      // track touch input
    trackMouse: false,                     // track mouse input
    rotationAngle: 0,                      // set a rotation angle
    swipeDuration: 250,               // allowable duration of a swipe (ms). *See Notes*
    touchEventOptions: { passive: true },  // options for touch listeners (*See Details*)

  })

  const changeTab = (tabAmount: string): void => {
    if (tabAmount === '7' && sevenDayReadings.length === 0) {
      fetchHistorical(sensor.id, '7', setSevenDayReadings);
    }
    if (tabAmount === '30' && thirtyDayReadings.length === 0) {
      fetchHistorical(sensor.id, '30', setThirtyDayReadings);
    }
    if (tabAmount === '0.25' && sixHourReadings.length === 0) {
      fetchHistorical(sensor.id, '0.25', setSixHourReadings);
    }
    if (tabAmount === '365' && oneYearReadings.length === 0) {
      fetchHistorical(sensor.id, '365', setOneYearReadings);
    }
    if (tabAmount === '0.1' && oneHourReadings.length === 0) {
      fetchHistorical(sensor.id, '0.1', setOneHourReadings);
    }
    setTabView(tabAmount);
  };

  const enableAndFetchLlm = () => {
    setGenAiEnabled(true);
    fetchLlm(sensor.dank_name, setGenAiText, setGenAiPending,);
    return false;
  }

  return <div>
    <Helmet>
      <title>{`${sensor.name} - ${(sensor.kind == 'PWS') ? 'Local Personal Weather Station' : 'Local Temperature Sensor'}`}</title>
      <link rel="canonical" href={`https://dankweather.com/sensor/${sensorName}`} />
    </Helmet>
    <Header />
    {(typeof sensor.name === 'undefined' || typeof currentReading.temperature === 'undefined') && <div className="sensor-loading-container">
      <div className="title">
        Loading sensor data...
      </div>
      <div className="details">
        live data...{typeof sensor.name !== 'undefined' && <>done</>}
      </div>
      <div className="details">
        historical data...{typeof currentReading.temperature !== 'undefined' && <>done</>}
      </div>
    </div>}
    {typeof sensor.name !== 'undefined' && typeof currentReading.temperature !== 'undefined' && <div className="sensor-detail-container" {...swipeHandlers}>
      <div className="toggle-container">
        <Toggle id={sensor.id} />
      </div>
      <div className="page-title-container">
        <h1>{sensor.name}</h1>
        <div className="sensor-subheader">
          {sensor.location} {sensor.kind} from {sensor.username}
        </div>
        <div className="sensor-updated">
          Last Update: {timeSinceFormatter(timeSince)}
        </div>
      </div>
      <div className='top-containers'>
        <div className='solar-radiation-container'>{currentReading.solar_radiation}</div>
        {/* <div className='uv-index-container'><br /><br /><br /><br /><br /><br />UV {currentReading.uv}</div> */}
      </div>

    </div>}
  </div>;
};


export { SolarDetailsContainer };