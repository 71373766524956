

const calculateRadius = (zoomLevel: number): number => {
    switch (zoomLevel) {
        case 18:
            return 150;
        case 17:
            return 100;
        case 16:
            return 50;
        case 15:
            return 36;
        case 14:
            return 28;
        case 13:
            return 20;
        case 12:
            return 16;
        case 11:
            return 12;
        case 10:
            return 10;
        case 9:
            return 8;
        case 8:
            return 6;
        case 7:
            return 4;
        case 6:
            return 2;
        case 5:
            return 2;
        case 4:
            return 1;
        case 3:
            return 1;
        default:
            return 1;
    }
};

export { calculateRadius };